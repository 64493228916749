<template>
  <div class="staffList">
    <!-- 员工列表 -->
    <div v-show="!showBussinessList">
      <div class="timeChoiceDiv flexbox flex-lr flex-align-center">
        <div class="timeAndBtn flexbox flex-lr flex-align-center mr20">
          <div class="timeChoose">归属分公司</div>
          <el-select v-model="form.spreadId" placeholder="请选择分公司" clearable filterable remote
            :loading="loading" :remote-method="searchBranchOffice" @focus="searchBranchOffice('')">
            <el-option v-for="item in branchOfficeList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <!-- <div class="timeChoose ml20" v-if="form.spreadId">代理商</div>
          <el-select v-model="form.agentSid" placeholder="请选择代理商" clearable v-if="form.spreadId">
            <el-option v-for="item in agentList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select> -->
        </div>
        <el-button type="primary" class="sure" @click="search">筛选</el-button>
        <PrevReclick rights="p_staff_add">
          <el-button type="primary" class="sure" @click="openEditStaffPop('')">新增员工</el-button>
        </PrevReclick>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="staffId" label="员工编号"></el-table-column>
        <el-table-column prop="phone" label="员工电话"></el-table-column>
        <el-table-column prop="name" label="员工名称"></el-table-column>
        <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
            <span class="green" v-if="scope.row.state == 1">正常</span>
            <span class="red" v-else>禁用</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <!-- <el-button type="success" plain size="mini" @click="openEditStaffPop(scope.row.staffId)">编辑</el-button> -->
            <!-- <el-button type="primary" plain size="mini" @click="delStaff(scope.row.staffId)">删除</el-button> -->
            <PrevReclick rights="g_staff_mch_queryShopByStaffId">
                <el-button type="warning" plain size="mini" @click="switchBussinessList(scope.row.staffId)">商家列表</el-button>
            </PrevReclick>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <!-- 新增/编辑员工弹窗 -->
    <el-dialog title="员工信息"
        :visible.sync="editStaffPop"
        width="550px"
        center>
      <el-form ref="form" label-width="100px">
        <el-form-item label="归属分公司">
          <el-select v-model="currentStaff.spreadId" placeholder="请选择分公司" clearable filterable remote
            :loading="loading" :remote-method="searchBranchOffice" @focus="searchBranchOffice('')">
            <el-option v-for="item in branchOfficeList" :key="item.value"
                       :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工电话">
          <el-input v-model="currentStaff.phone"></el-input>
        </el-form-item>
        <el-form-item label="员工名称">
          <el-input v-model="currentStaff.name" :maxlength="10"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmitStaff">立即创建</el-button>
          <el-button @click="editStaffPop =false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    </div>
    <!-- 商家列表 -->
    <div v-show="showBussinessList">
      <el-button type="primary" plain @click="switchStaffList">返回上级</el-button>
      <div class="tableBox">
      <el-table :data="bussinessTableData" style="width: 100%" border>
        <el-table-column prop="shopName" label="商户昵称"></el-table-column>
        <el-table-column prop="contactTel" label="联系电话"></el-table-column>
        <el-table-column prop="shopLabel" label="店铺标签"></el-table-column>
        <el-table-column prop="" label="操作" width="120">
          <!-- <template slot-scope="scope">
            <el-button type="info" plain size="mini" @click="deleteShop(scope.row.staffShopId)">移除</el-button>
          </template> -->
        </el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="bussinessTotalPage"
                   :current-page.sync="bussinessCurrentPage" @current-change="bussinessPageChange">
    </el-pagination>
    </div>
  </div>
</template>
<script>
import { $sp_spreadList } from '@/api/order';
// import { $sp_agentList } from '@/api/agent';
import {
  $sg_staffList, $sp_staffAdd, $sg_getStaffDetail,

  $sp_staffUpdate, $sp_staffDelete, $sg_queryShopByStaffId,

  $sp_deleteShop,
} from '@/api/staff';

export default {
  data() {
    return {
      form: {
        spreadId: '',
        // agentSid: '',
      },
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      branchOfficeList: [],
      agentList: [],
      loading: false,
      editStaffPop: false, // 是否显示新增/编辑员工弹窗
      currentStaff: { // 新增/编辑员工form内容
        spreadId: '',
        phone: '',
        name: '',
      },
      showBussinessList: false, // 是否显示商家列表
      staffId: '',
      bussinessTotalPage: 1,
      bussinessCurrentPage: 1,
      bussinessTableData: [],
    };
  },
  created() {
    this.getStaffTableData();
  },
  methods: {
    // 筛选
    search() {
      this.currentPage = 1;
      this.getStaffTableData();
    },
    // 请求员工列表数据
    getStaffTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      $sg_staffList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 搜索归属分公司
    async searchBranchOffice(query) {
      this.loading = true;
      if (query) {
        await this.reviewedShopPage(query);
      } else {
        await this.reviewedShopPage('');
      }
      this.loading = false;
    },
    // 请求归属分公司数据
    reviewedShopPage(platName = '') {
      const params = {
        platName,
      };
      return $sp_spreadList(params).then((res) => {
        const tempArr = [];
        res.forEach((item) => {
          const json = {
            value: item.spreadId,
            label: item.platName,
          };
          tempArr.push(json);
        });
        this.branchOfficeList = tempArr;
      });
    },
    // 请求代理商列表
    // getAgentList() {
    //   this.form.agentSid = '';
    //   this.agentList = [];
    //   if (!this.form.spreadId) return;
    //   const params = {
    //     pageCurrent: 1,
    //     pageSize: 20,
    //     spreadId: this.form.spreadId,
    //   };
    //   $sp_agentList(params).then((res) => {
    //     const tempArr = [];
    //     res.records.forEach((item) => {
    //       const json = {
    //         value: item.agentSid,
    //         label: item.agentName,
    //       };
    //       tempArr.push(json);
    //     });
    //     this.agentList = tempArr;
    //   });
    // },

    // 打开新增/编辑员工弹窗
    openEditStaffPop(staffId) {
      if (staffId) {
        // 编辑员工
        this.getStaffDetail(staffId);
      } else {
        // 新增员工
        this.currentStaff = {
          spreadId: '',
          phone: '',
          name: '',
        };
      }
      this.editStaffPop = true;
    },
    // 查看员工详情
    getStaffDetail(staffId) {
      $sg_getStaffDetail(staffId).then((res) => {
        this.currentStaff = res;
        this.branchOfficeList = [
          {
            value: res.spreadId,
            label: res.platName,
          },
        ];
      });
    },
    // 添加/修改员工保存
    onSubmitStaff() {
      const params = {
        ...this.currentStaff,
      };
      if (params.staffId) {
        // 编辑员工
        $sp_staffUpdate(params).then(() => {
          this.editStaffPop = false;
          this.getStaffTableData();
        });
      } else {
        // 添加员工
        $sp_staffAdd(params).then(() => {
          this.editStaffPop = false;
          this.currentPage = 1;
          this.getStaffTableData();
        });
      }
    },
    // 删除员工
    delStaff(staffId) {
      this.$confirm('您确认要删除该员工吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        $sp_staffDelete(staffId).then(() => {
          this.getStaffTableData();
        });
      }).catch(() => {});
    },
    // 员工列表翻页
    pageChange() {
      this.getStaffTableData();
    },
    // 商家列表中返回上级-显示员工列表
    switchStaffList() {
      this.showBussinessList = false;
    },
    // 显示商家列表
    switchBussinessList(staffId) {
      this.staffId = staffId;
      this.getBussinessTableData();
      this.showBussinessList = true;
    },
    // 请求商家列表数据
    getBussinessTableData() {
      const params = {
        pageCurrent: this.bussinessCurrentPage,
        pageSize: 20,
        staffId: this.staffId,
      };
      $sg_queryShopByStaffId(params).then((res) => {
        this.bussinessTableData = res.records;
        this.bussinessTotalPage = res.pages;
      });
    },
    // 移除商家
    deleteShop(staffShopId) {
      this.$confirm('您确认要移除该商家吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        $sp_deleteShop(staffShopId).then(() => {
          this.getBussinessTableData();
        });
      }).catch(() => {});
    },
    // 商家列表翻页
    bussinessPageChange() {
      this.getBussinessTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.staffList{
  width: 100%;
  min-width: 1400px;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .timeChoose{
    margin-right: 8px;
  }
  .tableBox{
    width: 100%;
    margin-top: 40px;
  }
  .el-table{
    min-height: 580px;
    & > th {
      background-color: #EF3F46;
      color: #fff;
    }
  }
}
</style>
